.header-main {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 9999;
}
.safty {
  position: relative;
  width: 100%;
  background-color: #e5a2da;
}
.header-logo {
  height: 1.8rem;
  width: 1.8rem;
}
.friendzy-logo {
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
  margin: 10px;
}
.navbar-brand {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 0.5vw;
}
.header-link {
  font-size: 1.05rem;
  font-weight: 500;
  padding: 0.65rem 1.5rem;
  margin-left: 1px;
}
.logo-link{
  text-decoration: none;
}
.navbar-light .navbar-nav .nav-link {
  color: #fff;
}
.con-submit{
  color: #fff;
  text-decoration: none;
}

.sticky {
  background-color: #fcfcfc;
  box-shadow: 0 0 2px rgba(50, 50, 50, 0.4);
}

.safty.sticky{
  background-color: #fcfcfc;
  box-shadow: 0 0 2px rgba(50, 50, 50, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.sticky .navbar-light .navbar-nav .nav-link  {
  color: rgb(187, 187, 187);
  text-decoration: none;
}


.sticky .friendzy-logo {
  color: #dd88cf;
  text-decoration: none;
}

.sticky .con-submit {
  color: rgb(187, 187, 187);
  text-decoration: none;
}

.active {
  color: #7e1d6e;
  text-decoration: none;
}
.sticky .active {
  color: #dd88cf;
  text-decoration: none;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: #dd88cf;
}

.navbar-toggler-icon {
  width: 1em;
  height: 1em;
}

@media (min-width: 320px) and (max-width: 767px) {
  #navbarScroll {
    border-radius: 3px;
    background-color: #e7c9e2;
    text-align: left;
  }
}
@media  (min-width: 768px) and (max-width: 991px) {
  #navbarScroll {
    border-radius: 3px;
    background-color: #e7c9e2;
    text-align: left;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    justify-content: flex-end;
  }
  .navbar-expand-lg .navbar-nav {
    column-gap: 1.4rem;
  }
}
