.contact{
    min-height: calc(100vh - 163px);
    display: flex;
    justify-content: center;
    align-items: center;
}
.x_content{
    font-family: "Montserrat", sans-serif;
}
.contact-txt{
    margin-top: 1.3rem;
}
.contact-txt h3{
    margin-bottom: 1.9rem;
}
.contact-data{
    padding: 20px;
}
.contact-data1{
    padding: 30px;
    background-color: #fff;
    box-shadow: 0px 0px 25px 25px #f2f2f2;
}
.form-lbl{
    font-weight: 400;
}
.form-group{
    margin: 10px;
}
.submit-btn{
    margin: 10px;
    background-color: #dd88cf;
    color: #fff;
}
.contact-data input , .contact-data textarea{
    background-color: #f2f2f2
}