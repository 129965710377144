.download-main {
  height: 500px;
  width: 100%;
  position: relative;
}
.download-main .backimg {
  height: 100%;
  width: 100%;
}
.download-data {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(221, 136, 207, 0.7);
  min-height: 500px;
}
.d-subdata {
  position: absolute;
  width: 100%;
  text-align: center;
  align-items: center;
  height: 100%;
}
.d-heading {
  font-size: 2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.d-data {
  color: #fff;
}
.download-link {
  display: flex;
  justify-content: center;
  font-weight: 600;
}
.qr-data {
  margin-left: 15px;
  text-align: left;
  font-weight: 700;
}
.dow-img {
  display: flex;
  margin-top: 30px;
}
.down-mob {
  height: 30vw;
  object-fit: cover;
}
.qr-img {
  width: 30% !important;
}
.learn-btn:hover {
  background-color: #efb1e4;
  border: 1px solid #dd88cf;
}
.android-icon{
  background-color: #fff;
  border-radius: 5rem;
  display: flex;
  width: 100%;
  padding: 0px 20px;
  align-items: center;
  margin: 5px;
  width: 100%;
}
.and-icondata{
  font-size: 0.9rem;
  text-align: left;
  display: flex;
  flex-direction: column;
  width:100%;
  padding: 10px;
}
.and-icon{
 text-align: center;
}
.and-icontext{
  font-size: 0.7rem;
}
.icon-heading{
  font-size: 1rem;
}

@media (min-width: 320px) and (max-width: 767px) {
  .download-main {
    height: 800px;
  }
  .download-data {
    min-height: 800px;
  }
  .d-data {
    margin-top: 2rem;
  }
  .qr-img {
    width: 28% !important;
    height: 28% !important;
    padding: 10px;
  }
  .qr-data {
    font-size: 26px;
  }
  .dow-img {
    align-items: center;
    flex-direction: column;
  }
  .down-mob {
    height: 36vw;
  }
  .download-link{
    flex-direction: column;
    align-items: center;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .download-main {
    height: 500px;
  }
  .download-data {
    min-height: 500px;
  }
  .d-data {
    margin-top: 4rem;
  }
  .qr-img {
    width: 30% !important;
    height: 30% !important;
  }
  .qr-data {
    font-size: 26px;
  }
  .dow-img {
    align-items: center;
    flex-direction: column;
  }
  .down-mob {
    height: 38vw;
  }
}

@media (min-width: 1250px) and (max-width: 1950px) {
  .down-mob {
    height: 30vw;
  }
}

@media  (min-width: 1400px) {
  .download-main{
    height: 600px;
  }
  .download-data{
    min-height: 600px;
  }
}

