.fea-heading-main {
  margin-top: 6rem;
}
.feature-heading1 {
  font-size: 4rem;
  line-height: 75px;
  text-align: left;
  background-image: linear-gradient(
    83.78deg,
    #0088ff -6.9%,
    #a033ff 28.26%,
    #ff5c87 78.98%
  );
  -webkit-background-clip: text;
  color: transparent;
  display: inline-block;
  font-weight: 450;
  padding: 0 0 8px;
  word-break: break-word;
  margin: 5rem auto 10px;
}
.feature-img {
  text-align: end;
}

.fea-img {
  height: 40vw;
  object-fit: cover;
}

.video-play {
  height: 100%;
  width: 100%;
}

.feature-heading2 {
  font-size: 3rem;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 10px;
  line-height: 3rem;
}

@media (min-width: 320px) and (max-width: 767px) {
  .feature-heading1 {
    font-size: 2.5rem;
    line-height: 40px;
  }
  .feature-img {
    text-align: center;
  }
  .feature-heading2 {
    font-size: 2rem;
    line-height: 2rem;
  }
  .fea-heading-data {
    font-size: 0.9rem;
  }
  .fea-img {
    height: 60vw;
    align-items: center;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .feature-heading1 {
    font-size: 2.5rem;
    line-height: 45px;
  }
  .feature-heading2 {
    font-size: 2.5rem;
    line-height: 45px;
  }
}
