.dashboard_body .right_col{
    /* background-color: #e5e5e5; */
    background-color: rgba(221, 136, 207, 0.7);
    padding: 15px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width: 768px) {
    .dashboard_body  .utillity_height {
        min-height: 100vh !important;
    }
}

.dashboard_body .form-container {
    max-width: 860px;
    margin: 0 auto;
}

.dashboard_body .x_panel {
    border-radius: 0px;
    margin-bottom: 0px;
}

.dashboard_body .x_panel {
    width: 100%;
    height: 100%;
    padding: 10px 17px;
    display: inline-block;
    background: #fff;
    opacity: 1;
    transition: all .2s ease;
}

.dashboard_body .x_content {
    width: 100%;
}

.dashboard_body .x_content {
    padding: 0 5px 6px;
    float: left;
    clear: both;
}

.dashboard_body .form-group {
    margin-bottom: 1rem;
}

.dashboard_body label {
    max-width: 100%;
    font-weight: 700;
}

.dashboard_body label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

.dashboard_body .form-control {
    border-radius: 0;
    width: 100%;
    height: 40px;
}

.dashboard_body .form-group .form-control {
    box-shadow: none;
    display: block;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
}

.dashboard_body .header-area{
    display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10vw;
  margin-bottom: 1rem;
}

.dashboard_body .form-text {
    font-size: 1.8rem;
    font-weight: 600
}

.dashboard_body .dashboard_body h5{
    text-align: center;
    font-size: 18px;
    color: #656276;
}

.dashboard_body .form-contro {
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    color: #555;
    font-size: 14px;
    height: 40px;
    line-height: 1.42857143;
    padding: 6px 12px;
    position: relative;
    width: 100%;
}