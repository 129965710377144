.slider-container img{
    object-fit: cover;
}
.slider-container{
    margin: 2rem 0;
}
.screen-heading{
    font-size: 2.3rem;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif ;
    text-align: center;
    padding-top: 3.2rem;
    margin-bottom: 10px;
}
.img-screen img{
    height: 20vh;
    width: 100%;
    object-fit: contain;

}
.slick-list{
    padding: 1vw 1vw 1vw 1vw !important;
}
.slick-arrow .slick-prev , .slick-prev:before{
    background-color: #dd88cf;
    color: black;
    border-radius: 15px;
    align-items: center;
}
.slick-arrow .slick-next , .slick-next:before{
    background-color: #dd88cf;
    color: black;
    border-radius: 15px;
    align-items: center;
}
.slick-dots{
    display: none!important;
}

@media (min-width:320px) and (max-width:767px){
    .slider-container{
        margin: 1rem ;
    }
    .slider-container img {
        width: auto;
        object-fit: contain;
    }
}   
@media (min-width:768px) and (max-width:991px) {
    
}
@media (min-width: 500px) {
    .slick-initialized .slick-slide {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}