@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #333;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.5;
  /* background-color: rgb(243, 242, 239); */
}

h2 {
  font-family: "Montserrat", sans-serif;
}


@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(20%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@media (max-width: 768px){
  .fe{
    transform: translateX(0) translateY(0)!important;
    align-items: center;
  }
}