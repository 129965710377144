.about-main{
    padding: 3rem;
}
.about-heading{
    font-size: 2.3rem;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif ;
    text-align: center;
    margin-bottom: 10px;
}
.about-para{
    font-size: 1rem;
}


@media (min-width:320px) and (max-width:767px) {
    .about-main{
        padding: 1.8rem;
    }
    .about-heading{
        font-size: 1.6rem;
    }
    .about-para{
        font-size: 0.8rem;
    }
}