.header-logo {
    height: 1.8rem;
    width: 1.8rem;
    margin-bottom: 5px;
  }
  .logo-txt {
    font-size: 1.2rem;
    font-weight: 500;
    color: #dd88cf;
    margin-left: 8px;
  }
.footer-main {
  margin-top: 1rem;
}
.footer-data{
    text-align: right;
}
.footer-para{
    font-size: 0.7rem;
    width: 60%;
}
.copyright{
    text-align: left;
    font-size: 0.8rem;   
}
.footer-link{
  color: black !important;
  text-decoration: none;
  margin: 4px;
}
