.home-main {
  height: 600px;
  width: 100%;
  background: linear-gradient(
      rgba(242, 166, 217, 0.7),
      rgba(243, 145, 215, 0.7)
    ),
    url(../assets/images/img1.jpg);
  background-size: cover;
  background-position: bottom;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mob-banner1 {
  height: 100%;
  width: 100%;
}
.home-data {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(221, 136, 207, 0.7);
  min-height: 600px;
}
.home-subdata {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: 10%;
  text-align: center;
}
.banner-heading {
  font-size: 3rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  text-align: left;
}
.banner-data {
  margin-left: 6rem;
  margin-top: 5rem;
  color: #fff;
}
.banner-para {
  font-size: 1rem;
}
.mobile-img {
  aspect-ratio: 1.4;
}
.learn-btn {
  background-color: #fff;
  color: #dd88cf;
  border: none;
  font-weight: 600;
  margin-top: 2rem;
}
.learn-btn:hover {
  background-color: #efb1e4;
  color: #fff;
}

@media (min-width: 320px) and (max-width: 767px) {
  .home-main {
    height: 600px;
  }
  .home-data {
    min-height: 600px;
  }
  .banner-data {
    margin-top: 2rem;
    margin-left: 3rem;
  }
  .banner-heading {
    font-size: 2rem;
  }
  .banner-para {
    font-size: 0.8rem;
  }
  .learn-btn {
    margin-top: 0px;
    padding: 5px;
    font-size: 15px;
  }
  .mobile-img {
    width: 60% !important;
    height: 60vw;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .banner-data {
    margin-top: 1.5rem;
    margin-left: 3rem;
    font-size: 2.5rem;
  }
  .banner-para {
    font-size: 0.9rem;
  }
  .mobile-img {
    width: 80% !important;
    height: 50vw;
  }
}
