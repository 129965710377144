.privacy-main {
  padding: 20px;
  margin: 10px 0px;
} 
.privacy-heading1 {
  font-size: 3rem;
  margin-top: 1.5rem;
  text-align: left;
  background-image: linear-gradient(
    83.78deg,
    #0088ff -6.9%,
    #a033ff 28.26%,
    #ff5c87 78.98%
  );
  -webkit-background-clip: text;
  color: transparent;
  display: inline-block;
  font-weight: 450;
  word-break: break-word;
  margin: 0rem auto 10px;
}
.privacy-img {
  width: 100%;
  object-fit: cover;
  display: flex;
  border-radius: 30px;
}

@media (min-width: 320px) and (max-width: 767px) {
  .privacy-heading1 {
    font-size: 2rem;
  }
  .head-data1 {
    font-size: 1rem;
  }
  .privacy-img {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 800px) {
  .privacy-heading1 {
    font-size: 2.2rem;
  }
  .privacy-data {
    width: 100%;
  }
}

@media (min-width: 801px) and (max-width: 1150px)  {
  .privacy-heading1 {
    font-size: 2.3rem;
  }
}

@media (min-width:1300px) and (max-width: 1700px) {
  .privacy-heading1 {
    font-size: 2.6rem;
    margin-top: 1.5rem;
  }
  .privacy-data {
    width: 100%;
  }
  .privacy-img {
    width: 100%;
  }
}


/* privacy & safty  */

.safty-para{
  text-align: justify;
}
.safty-data{
  font-family: "Montserrat", sans-serif;
  margin: 2rem 0;
}
.privacy-heading{
  text-align: center;
  margin: 10px;
  font-size: 2rem;
  font-weight: 500;
}
